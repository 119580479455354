/* eslint-disable import/prefer-default-export */
import { KycVerificationStatus } from './types'

export const shouldMakeKycAttempt = (
  kyc: KycVerificationStatus | 'Suspended'
) => !['Approved', 'InReview'].includes(kyc)

export const userKycInReview = ({
  companyKyc,
  employerKyc,
}: {
  companyKyc: KycVerificationStatus | 'Suspended'
  employerKyc: KycVerificationStatus | 'Suspended'
}) => companyKyc === 'InReview' || employerKyc === 'InReview'

export const userKycPending = ({
  companyKyc,
  employerKyc,
}: {
  companyKyc: KycVerificationStatus | 'Suspended'
  employerKyc: KycVerificationStatus | 'Suspended'
}) => companyKyc === 'Pending' || employerKyc === 'Pending'
export const userKycRejected = ({
  companyKyc,
  employerKyc,
}: {
  companyKyc: KycVerificationStatus | 'Suspended'
  employerKyc: KycVerificationStatus | 'Suspended'
}) => companyKyc === 'Rejected' || employerKyc === 'Rejected'
