import { Stringifiable } from 'query-string'

import { JobReducerState } from './job-detail/reducers/JobReducer'

type QueryKeys = keyof JobReducerState['jobListingQueries']

export function jobListingQueriesToServerRequest(
  queries: JobReducerState['jobListingQueries']
): Record<PropertyKey, Stringifiable> {
  const result: Record<PropertyKey, Stringifiable> = {}

  Object.keys(queries).forEach((key) => {
    const value = queries[key as QueryKeys]

    if (Array.isArray(value)) {
      result[key] = JSON.stringify(value)
    } else {
      result[key] = value as string | undefined
    }
  })

  return result
}
