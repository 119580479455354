/* eslint-disable @next/next/no-img-element */

/* eslint-disable no-use-before-define */
import classNames from 'classnames'
import { useRouter } from 'next/router'
import React from 'react'
import useSWR from 'swr'
import useSWRImmutable from 'swr/immutable'

import { shouldMakeKycAttempt } from '@features/kyc/helpers'
import { useKycActions } from '@features/kyc/reducers/KycReducer'
import {
  CompanyKyc,
  EmployerKyc,
  GetEmployerProfileAPIResponseData,
  KycVerificationStatus,
} from '@features/kyc/types'

import Button from '@components/button'
import Modal from '@components/modal/modal'

import { useAppSelector } from '@hooks/redux'

import { KYC_STATUS } from '@constants/kyc-status'
import { ATMA_CS_PHONE_NUMBER } from '@constants/phone-number'
import {
  COMPANY_KYC_ATTEMPTS_URL,
  EMPLOYERS_KYC_ATTEMPTS_URL,
  EMPLOYERS_PROFILE_URL,
} from '@constants/url'
import shareToWhatsApp from '@constants/whatsapp-url'

import { VerificationStepperProps } from './types'

const EmployeeVerificationIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.33398 5.33333H12.6673M10.0007 8H12.6673M11.334 10.6667H12.6673M11.334 14H4.66732C2.00065 14 1.33398 13.3333 1.33398 10.6667V5.33333C1.33398 2.66667 2.00065 2 4.66732 2H11.334C14.0007 2 14.6673 2.66667 14.6673 5.33333V10.6667C14.6673 13.3333 14.0007 14 11.334 14Z"
      stroke="#4460AC"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.00065 10.8866C7.95578 10.4186 7.74856 9.98113 7.41493 9.64993C7.08129 9.31873 6.64227 9.11472 6.17398 9.07328C5.83703 9.03995 5.49761 9.03995 5.16065 9.07328C4.69282 9.11597 4.25451 9.32039 3.92111 9.65134C3.58772 9.9823 3.3801 10.4191 3.33398 10.8866M5.66732 7.52661C5.98735 7.52661 6.29427 7.39948 6.52056 7.17319C6.74685 6.9469 6.87398 6.63998 6.87398 6.31995C6.87398 5.99992 6.74685 5.693 6.52056 5.46671C6.29427 5.24041 5.98735 5.11328 5.66732 5.11328C5.34729 5.11328 5.04037 5.24041 4.81408 5.46671C4.58778 5.693 4.46065 5.99992 4.46065 6.31995C4.46065 6.63998 4.58778 6.9469 4.81408 7.17319C5.04037 7.39948 5.34729 7.52661 5.66732 7.52661Z"
      stroke="#4460AC"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const ComapnyVerificationIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_114_18450)">
      <path
        d="M1.33203 14.6663H14.6654M4.66536 10.9997H6.66536M9.33203 10.9997H11.332M4.66536 7.99967H6.66536M9.33203 7.99967H11.332M4.66536 4.99967H6.66536M9.33203 4.99967H11.332M11.332 1.33301H4.66536C2.66536 1.33301 1.9987 2.52634 1.9987 3.99967V14.6663H13.9987V3.99967C13.9987 2.52634 13.332 1.33301 11.332 1.33301Z"
        stroke="#4460AC"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_114_18450">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

const verificationChipStyles: Record<
  KycVerificationStatus | 'Suspended',
  string
> = {
  Pending: '',
  InReview: 'bg-sunshine-50 text-sunshine-700',
  Rejected: 'bg-rejected-0 text-red-700',
  Approved: 'bg-hired-0 text-green-700',
  Suspended: '',
}

const verificationChipText: Record<
  KycVerificationStatus | 'Suspended',
  string
> = {
  Pending: '',
  InReview: 'Sedang ditinjau',
  Rejected: 'Ditolak',
  Approved: 'Disetujui',
  Suspended: 'Ditangguhkan',
}

const VerificationChip = ({
  status,
}: {
  status?: KycVerificationStatus | 'Suspended'
}) => {
  if (!status || status === 'Pending') {
    return null
  }
  return (
    <span
      className={`ml-[4px] rounded-2xl px-[8px] py-[4px] text-[12px] font-normal ${verificationChipStyles[status]}`}
    >
      {verificationChipText[status]}
    </span>
  )
}

const VerificationMessage = ({ message }: { message?: React.ReactNode }) => {
  if (!message) {
    return null
  }
  if (typeof message === 'string') {
    return <p className="mb-[4px] text-[12px] text-red-700">{message}</p>
  }
  return message
}

const VerificationStepper = ({
  companyVerification,
  employeeVerification,
}: VerificationStepperProps) => (
  <ul className="mb-[24px] list-none">
    <li className="mb-4">
      <div className="mb-[4px] flex items-center gap-x-[8px]">
        <span
          className={classNames(
            'w-[28px]',
            'h-[28px]',
            'border',
            'border-[1px]',
            'border-primary-blue',
            'bg-white',
            'rounded-full',
            'flex',
            'items-center',
            'justify-center'
          )}
        >
          <EmployeeVerificationIcon />
        </span>
        <span className="flex items-center text-[18px] font-semibold">
          <p className="text-[18px] font-semibold">Verifikasi Diri </p>
          <VerificationChip status={employeeVerification.status} />
        </span>
      </div>
      <div className="ml-[36px]">
        <p className="mb-[4px]">
          Untuk memastikan informasi pribadi sebagai rekruter adalah benar.
        </p>
        <VerificationMessage message={employeeVerification.message} />
      </div>
    </li>
    <li>
      <div className="flex items-center gap-x-[8px]">
        <span
          className={classNames(
            'w-[28px]',
            'h-[28px]',
            'border',
            'border-[1px]',
            'border-primary-blue',
            'bg-white',
            'rounded-full',
            'flex',
            'items-center',
            'justify-center'
          )}
        >
          <ComapnyVerificationIcon />
        </span>
        <span className="flex items-center text-[18px] font-semibold">
          <p className="text-[18px] font-semibold">
            Verifikasi Data Perusahaan
          </p>
          <VerificationChip status={companyVerification.status} />
        </span>
      </div>
      <div className="ml-[36px]">
        <p className="mb-[4px]">
          Untuk memastikan informasi tentang perusahaan adalah benar.
        </p>
        <VerificationMessage message={companyVerification.message} />
      </div>
    </li>
  </ul>
)

const PreKycVerificationDataModal = () => {
  const open = useAppSelector((state) => state.kyc.showPreKycVerificationModal)
  const kycActions = useKycActions()
  const { data: employerProfileData } =
    useSWRImmutable<GetEmployerProfileAPIResponseData>(EMPLOYERS_PROFILE_URL)
  const router = useRouter()
  const { data: employerKycAttemptsData } = useSWR<EmployerKyc[]>(
    open ? EMPLOYERS_KYC_ATTEMPTS_URL : null
  )
  const { data: companyKycAttemptsData } = useSWR<CompanyKyc[]>(
    employerProfileData?.company?.id && open
      ? COMPANY_KYC_ATTEMPTS_URL(employerProfileData.company.id)
      : null
  )

  const isEkycRejected = employerProfileData?.kycStatus === 'Rejected'
  const isCkycRejected = employerProfileData?.company?.kycStatus === 'Rejected'

  const employerKycStatus = employerProfileData?.kycStatus
  const companyKycStatus = employerProfileData?.company?.kycStatus

  const hasNoEmployerKycAttempt =
    employerKycAttemptsData && employerKycAttemptsData.length <= 0
  const hasNoCompanyKycAttempt =
    companyKycAttemptsData && companyKycAttemptsData.length <= 0

  // We use first index here as the latest attempt
  const employerKycMessage = hasNoEmployerKycAttempt
    ? null
    : employerKycAttemptsData?.[0]?.rejectionReasonTranslation || null

  const companyKycMessage = hasNoCompanyKycAttempt
    ? null
    : companyKycAttemptsData?.[0]?.rejectionReasonTranslation || null

  const ableToMakeBothKycAttempt = React.useMemo(() => {
    if (!employerKycStatus || !companyKycStatus) {
      return false
    }
    if (
      !shouldMakeKycAttempt(employerKycStatus) &&
      !shouldMakeKycAttempt(companyKycStatus)
    ) {
      return false
    }

    return true
  }, [companyKycStatus, employerKycStatus])

  const buttonText = () => {
    if (!employerProfileData) {
      return '-'
    }
    if (employerProfileData.kycStatus === 'Rejected') {
      return 'Verifikasi ulang'
    }
    if (employerProfileData.company?.kycStatus === 'Rejected') {
      return 'Verifikasi ulang'
    }
    if (!ableToMakeBothKycAttempt) {
      return 'Kami sedang meninjau informasi Anda'
    }

    return 'Mulai verifikasi'
  }

  const onClickPrimaryButton = () => {
    kycActions.setOpenPreKycVerificationModal(false)
    router.push({ pathname: '/kyc', query: { step: 'pre-kyc' } })
  }

  return (
    <Modal
      open={open}
      onClose={() => kycActions.setOpenPreKycVerificationModal(false)}
    >
      <div className="max-w-[520px]">
        <h2 className="mb-[8px] text-center text-[24px] font-semibold">
          Verifikasi data
        </h2>

        <VerificationStepper
          companyVerification={{
            message: companyKycMessage,
            status: companyKycStatus,
          }}
          employeeVerification={{
            message: employerKycMessage,
            status: employerKycStatus,
          }}
        />
        {ableToMakeBothKycAttempt && (
          <Button
            appearance="primary"
            type="button"
            fullWidth
            className="mb-[16px] mt-[32px]"
            onClick={onClickPrimaryButton}
            disabled={!ableToMakeBothKycAttempt}
          >
            {buttonText()}
          </Button>
        )}
        {(isEkycRejected ||
          isCkycRejected ||
          employerKycStatus === KYC_STATUS.IN_REVIEW ||
          companyKycStatus === KYC_STATUS.IN_REVIEW) && (
          <Button
            appearance="outline-primary"
            type="button"
            fullWidth
            className="mt-[16px]"
            onClick={() => {
              window.open(
                shareToWhatsApp('+62', ATMA_CS_PHONE_NUMBER),
                '_blank'
              )
            }}
          >
            Hubungi CS
          </Button>
        )}
      </div>
    </Modal>
  )
}

export default PreKycVerificationDataModal
